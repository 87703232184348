import Button from "common/components/Button";
import CalculateRoundedIcon from "@mui/icons-material/CalculateRounded";
import Container from "common/components/UI/Container";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import MuiButton from "@mui/material/Button";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";
import ReactPlayer from "react-player";
import Slide from "@mui/material/Slide";
import Text from "common/components/Text";
import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Popup } from "react-typeform-embed";
import { graphql, useStaticQuery } from "gatsby";
import { green, orange } from "@mui/material/colors";
import { useWindowSize } from "common/hooks/useWindowSize";
import { v4 as uuid4 } from "uuid";

import SectionWrapper, { SectionWrapperAlt } from "./hero.style";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VideoDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      fullScreen={false}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        {"What is the Employee Retention Credit?"}
      </DialogTitle>
      <DialogContent>
        <ReactPlayer
          playing
          controls={true}
          width="100%"
          height="350px"
          url="https://www.youtube-nocookie.com/embed/U653NdG8bfY"
        />
      </DialogContent>
      <DialogActions>
        <Button
          title="Close"
          variant="textButton"
          autoFocus
          onClick={handleClose}
        />
      </DialogActions>
    </Dialog>
  );
};

const Hero = () => {
  const { width } = useWindowSize();
  const [videoIsOpen, setVideoIsOpen] = useState(false);
  const uuid = uuid4();
  const data = useStaticQuery(graphql`
    query {
      ertCreditJson {
        HERO_ITEMS {
          title
          text
          playButton {
            publicURL
          }
          videoCard {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          imageBg {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          imageBgMobile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                quality: 100
              )
            }
          }
          people {
            title
            personImage {
              publicURL
            }
            credit
            imageAsContent {
              publicURL
            }
          }
        }
      }
    }
  `);
  const { title, text, image, imageBg, imageBgMobile, people } =
    data.ertCreditJson.HERO_ITEMS;

  const handleClose = () => {
    setVideoIsOpen(false);
  };

  return (
    <>
      <SectionWrapper>
        <Container width="1460px">
          {width > 568 ? (
            <GatsbyImage
              src={
                (imageBg !== null) | undefined
                  ? imageBg.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          ) : (
            <GatsbyImage
              src={
                (imageBgMobile !== null) | undefined
                  ? imageBgMobile.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Background"
            />
          )}
        </Container>
      </SectionWrapper>
      <SectionWrapperAlt>
        <Container width="1420px" className="hero-content">
          <div className="content-wrapper">
            <Heading as="h1" content={title} />
            <Text content={text} className="text" />
            <VideoDialog open={videoIsOpen} handleClose={handleClose} />
            <div className="button-wrapper">
              {/* <Button
                title="Learn"
                variant="altTextButton"
                onClick={() => setVideoIsOpen(true)}
                icon={<PlayCircleRoundedIcon />}
                iconPosition="left"
                className="learn-btn"
            /> */}
              <Popup
                id="hKYt1m38"
                hidden={{
                  uuid: uuid,
                }}
                onSubmit={(event) => {
                  //console.log(event.response_id);
                  window.location.replace(`/calculator-results/${uuid}`);
                }}
              >
                <Button
                  title="Get Started Today"
                  variant="textButton"
                  icon={<CalculateRoundedIcon />}
                  iconPosition="left"
                  onClick={() => (window.location.href = "/new-customer")}
                  className="get-started"
                />
              </Popup>
            </div>
          </div>
          <div className="image">
            <GatsbyImage
              src={
                (image !== null) | undefined
                  ? image.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Hero Image"
            />
            {people.map((person, index) => {
              return (
                <div key={index}>
                  {person.imageAsContent === null ? (
                    <div className={`person-${index + 1}`}>
                      <img
                        className="person-img"
                        src={person.personImage.publicURL}
                        alt={person.title}
                      />
                      <div className="text-container">
                        <Text className="title" content={person.title} />
                        <p className="credit">
                          Credit Amount: <span>{person.credit}</span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className={`person-${index + 1}`}>
                      <img
                        className="person-img"
                        src={person.personImage.publicURL}
                        alt={person.title}
                      />
                      <div className="text-container">
                        <img
                          className="text-img"
                          src={person.imageAsContent.publicURL}
                          alt={person.imageAsContent}
                        />
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </Container>
      </SectionWrapperAlt>
    </>
  );
};

export default Hero;
