import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

const SectionWrapper = styled.section`
  margin-top: 90px;
  @media screen and (max-width: 990px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 568px) {
  }
  overflow: hidden;
`;

export const SectionWrapperAlt = styled.div`
  margin: 0 20px;
  .hero-content {
    background-color: #eceff1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -250px;
    z-index: 1;
    padding-bottom: 60px;
    @media screen and (max-width: 1250px) {
      margin-top: -200px;
    }
    @media screen and (max-width: 1024px) {
      justify-content: space-between;
      align-items: center;
      display: flex;
    }

    @media screen and (max-width: 768px) {
      justify-content: space-between;
      align-items: center;
      flex-direction: flex;
      flex-direction: column;
      margin-top: -110px;
    }
    @media screen and (max-width: 568px) {
      margin-top: -120px;
      padding-bottom: 40px;
    }
    @media screen and (max-width: 440px) {
      flex-direction: column;
      margin-top: -100px;
    }
  }

  .content-wrapper {
    z-index: 1;
    width: 585px;
    margin-left: 120px;
    @media screen and (max-width: 990px) {
      margin-left: 20px;
      margin-right: 30px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      margin-left: 30px;
    }

    .text {
      max-width: 380px;
      font-size: ${themeGet("fontSizes.5")}px;
      line-height: 28px;
      font-weight: 400;
      margin-top: 32px;
      margin-bottom: 40px;
      color: ${themeGet("colors.text")};
      @media screen and (max-width: 990px) {
        margin-top: 24px;
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
        margin-bottom: 28px;
      }
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
      @media screen and (max-width: 568px) {
        margin-top: 16px;
        font-size: ${themeGet("fontSizes.2")}px;
        line-height: 20px;
        margin-bottom: 25px;
        max-width: 300px;
      }
    }

    .button-wrapper {
      display: flex;
      @media screen and (max-width: 1290px) {
        flex-direction: column;
      }

      @media screen and (max-width: 568px) {
        max-width: 100%;
      }
      .reusecore__button {
        @media screen and (max-width: 1290px) {
          width: 100%;
        }
        @media screen and (max-width: 568px) {
          min-width: 95px !important;
          height: 38px;
          font-size: ${themeGet("fontSizes.4")}px;
        }
      }
      .learn-btn {
        margin-right: 10px;
        @media screen and (max-width: 1290px) {
          margin-bottom: 10px;
          margin-right: 0px;
        }
      }
      .get-started {
        padding: 30px;
        width: 300px;
        margin-left: auto;
        margin-right: auto;
        font-size: 20px;
      }
    }
  }

  .image {
    position: relative;
    min-width: 583px;
    margin-left: 20px;
    @media screen and (max-width: 1100px) {
      min-width: 450px;
      max-width: 450px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 50px;
    }
    @media screen and (max-width: 600px) {
      margin-top: 60px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    /* Person 1 */
    .person-1 {
      position: absolute;
      top: 25px;
      display: flex;
      @media screen and (max-width: 768px) {
        top: 5px;
      }
      flex-direction: column;
      .text-container {
        padding: 20px 18px;
        background: ${themeGet("colors.white")};
        width: 191px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        box-shadow: -11.4674px 15.481px 57.3369px -17.2011px rgba(0, 0, 0, 0.6);
        @media screen and (max-width: 1100px) {
          width: 155px;
          padding: 14px 12px;
        }
        @media screen and (max-width: 768px) {
          width: 118px;
          padding: 10px 8px;
          top: 15px;
        }
      }
      .person-img {
        position: relative;
        width: 92px;
        top: 25px;
        right: -165px;
        @media screen and (max-width: 1100px) {
          width: 65px;
          top: 16px;
          right: -140px;
        }
        @media screen and (max-width: 768px) {
          width: 52px;
          right: -104px;
        }
      }
      .title {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: ${themeGet("fontSizes.5")}px;
        width: 100%;
        @media screen and (max-width: 1100px) {
          font-size: ${themeGet("fontSizes.1")}px;
        }
        @media screen and (max-width: 768px) {
          font-size: 9px;
        }
      }
    }
    .credit {
      margin-top: -2px;
      color: #686c71;
      letter-spacing: -1%;
      font-size: ${themeGet("fontSizes.2")}px;
      @media screen and (max-width: 1100px) {
        font-size: ${themeGet("fontSizes.0")}px;
      }
      @media screen and (max-width: 768px) {
        font-size: 7px;
      }
      span {
        color: #1e88a4;
        font-weight: 600;
        letter-spacing: -1%;
        font-size: ${themeGet("fontSizes.2")}px;
        @media screen and (max-width: 1100px) {
          font-size: ${themeGet("fontSizes.0")}px;
        }
        @media screen and (max-width: 768px) {
          font-size: 7px;
        }
      }
    }
  }

  /* Person 2 */
  .person-2 {
    position: absolute;
    top: 200px;
    right: 0px;
    display: flex;
    flex-direction: column-reverse;
    @media screen and (max-width: 1100px) {
      top: 120px;
    }
    @media screen and (max-width: 768px) {
      top: 94px;
    }
    .text-container {
      padding: 20px 18px;
      background: ${themeGet("colors.white")};
      width: 191px;
      border-top-left-radius: 15px;
      border-bottom-right-radius: 15px;
      box-shadow: -11.4674px 15.481px 57.3369px -17.2011px rgba(0, 0, 0, 0.6);
      @media screen and (max-width: 1100px) {
        width: 155px;
        padding: 14px 12px;
      }
      @media screen and (max-width: 768px) {
        width: 118px;
        padding: 10px 8px;
      }
    }
    .person-img {
      position: relative;
      width: 71px;
      top: -13px;
      left: -57px;
      @media screen and (max-width: 1100px) {
        width: 51px;
        top: -9px;
        left: -42px;
      }
      @media screen and (max-width: 768px) {
        width: 40px;
        top: -6px;
        left: -32px;
      }
    }
    .title {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: ${themeGet("fontSizes.5")}px;
      @media screen and (max-width: 1100px) {
        font-size: ${themeGet("fontSizes.1")}px;
      }
      @media screen and (max-width: 768px) {
        font-size: 9px;
      }
    }
    .credit {
      margin-top: -2px;
      color: #686c71;
      letter-spacing: -1%;
      font-size: ${themeGet("fontSizes.2")}px;
      @media screen and (max-width: 1100px) {
        font-size: ${themeGet("fontSizes.0")}px;
      }
      @media screen and (max-width: 768px) {
        font-size: 7px;
      }
      span {
        color: #1e88a4;
        font-weight: 600;
        letter-spacing: -1%;
        font-size: ${themeGet("fontSizes.2")}px;
        @media screen and (max-width: 1100px) {
          font-size: ${themeGet("fontSizes.0")}px;
        }
        @media screen and (max-width: 768px) {
          font-size: 7px;
        }
      }
    }
  }

  /* Person 3 */
  .person-3 {
    position: absolute;
    top: 315px;
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1100px) {
      top: 247px;
    }
    @media screen and (max-width: 768px) {
      top: 182px;
    }

    .text-container {
      padding: 20px 18px;
      background: ${themeGet("colors.white")};
      width: 191px;
      border-top-left-radius: 15px;
      border-bottom-right-radius: 15px;
      display: flex;
      max-height: 76px;
      box-shadow: -11.4674px 15.481px 57.3369px -17.2011px rgba(0, 0, 0, 0.6);
      @media screen and (max-width: 1100px) {
        width: 155px;
        padding: 10px 12px;
      }
      @media screen and (max-width: 768px) {
        width: 118px;
        padding: 10px 8px;
      }
    }
    .person-img {
      position: relative;
      width: 108px;
      margin-right: 12px;
      @media screen and (max-width: 1100px) {
        width: 76px;
      }
      @media screen and (max-width: 768px) {
        width: 62px;
      }
    }
    .text-img {
      margin: 0 auto;
      position: relative;
      width: 108px;
      @media screen and (max-width: 768px) {
        width: 72px;
      }
    }
    /* .title {
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: ${themeGet("fontSizes.5")}px;
    }
    .credit {
      margin-top: -2px;
      color: #686c71;
      letter-spacing: -1%;
      font-size: ${themeGet("fontSizes.2")}px;
      span {
        color: #1e88a4;
        font-weight: 600;
        letter-spacing: -1%;
        font-size: ${themeGet("fontSizes.2")}px;
      }
    } */
  }
`;

export default SectionWrapper;
