import * as React from "react";
import Customers from "containers/Customers";
import Faq from "containers/Faq";
import Features from "containers/Features";
import Hero from "containers/Hero";
import HowItWorks from "containers/HowItWorks";
import Layout from "common/components/Layout";
import Seo from "components/seo";
import WhyUs from "containers/WhyUs";
import TestimonialsMixed from "containers/TestimonialsMixed";
import Results from "containers/Results";

const HomePage = ({ location }) => {
  return (
    <Layout location={location.pathname}>
      <Seo title="Home" />
      <Hero />
      <Features />
      <Customers />
      <TestimonialsMixed/>
      <HowItWorks />
      <WhyUs />
      <Results />
      <Faq />
    </Layout>
  );
};

export default HomePage;
